
import { Navigate,Outlet } from "react-router-dom";

import {auth} from "../firebase/config";


const ProtectedRoute=() => {
    let user= false;
    if (auth.currentUser !==null){
         user=true;
    }
    else
    {user=false;}






    console.log("auth",user);


    return (
      user ?<Outlet/>:<Navigate to={"/login"}/>
    );
}

export default ProtectedRoute;
