import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {authFirebase} from "../firebase/config";









function Navigation() {
    const firebaseInstance = authFirebase;
    const navigate=useNavigate();

    const signOut = async () => {
        try {
            if (firebaseInstance) {
                await firebaseInstance.signOut();
                navigate("../login")
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    return (
        <nav>
            <Link to={"/login"}>   <Button>Login </Button></Link>
            <Link to={"/home"}>   <Button>Home </Button></Link>
            <Link to ={"/times"}> <Button>Times</Button>  </Link>
            <Button onClick={() => signOut()} >Logout</Button>

        </nav>
    );
}

export default Navigation;
