import React, {useEffect, useState} from 'react';
import {database} from "../firebase/config";




function Dashboard() {
    const [value,setValue]=useState('');

    useEffect(()=>
    {
        const ref = database.ref("timestamp/-NDEgT1wQUPfBmODsPJb");
        ref.on("value", (snapshot) => {
            setValue(snapshot.val());
        });
        return () => ref.off();
    },[]);




if(!value) return <div>Loading</div>;
            return (
        <div>
            <pre>{JSON.stringify(value,null,2)}</pre>
        </div>
    );
}

export default Dashboard;
