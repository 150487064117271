
import React from 'react';
import './App.css';
import Login from "./screens/Login";
import Navigation from "./components/Navigation";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "./screens/Home";
import {Typography} from "@mui/material";
import ProtectedRoute from "./components/ProtectedRoute";

import Dashboard from "./screens/Dashboard";

function App() {
  return (
      <>
          <Typography component="h5" variant="h5">
              Navigation
          </Typography>


          <BrowserRouter>
              <Navigation/>
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<Home/>} />
                <Route path="/home" element={<Home/>} />
                <Route path="/times" element={<Dashboard/>}/>

          <Route path="*" element={<p>There's nothing here: 404</p>}/>
            </Route>
            <Route element={<Login/>} path="/login"/>
        </Routes>
              </BrowserRouter>

      </>
  );
}

export default App;
