import React from 'react';
import {Typography} from "@mui/material";

import Serial from "../rs232/Serial";

function Home() {
    return (
        <>
        <Typography component="h1" variant="h5">
        Home
        </Typography>
          <Serial/>
        </>
    );
}

export default Home;
