import React, {SetStateAction, useState} from 'react';
import {Button, Grid, Input, Typography} from "@mui/material";
import {auth, authFirebase} from "../firebase/config";
import Alert from '@mui/material/Alert';
import {Navigate, useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth";


const useInput = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setValue(event.target.value);
    };
    return {
        value,
        onChange: handleChange,
    };
};


function Login() {

    const email=useInput('');
    const password=useInput('');
    const firebaseInstance=authFirebase;
    const [hasError,setHasError]=useState(false);
    const [user,setUser]=useState(false);
    const navigate=useNavigate();

    const signUp=async (event: React.FormEvent)=>{
        event.preventDefault();

        try {
            if (firebaseInstance) {
                await firebaseInstance.signInWithEmailAndPassword(email.value, password.value);

                console.log("logged in");
                navigate("../home");


            }
        } catch (error){
                setHasError(true);
            }
        };


    return (
        <div>

            <Typography component="h1" variant="h5">
                Login
            </Typography>
            <form  noValidate onSubmit={signUp}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Input
                            placeholder="Email"
                            {...email}
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            placeholder="Password"
                            {...password}
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                    </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="secondary" >
                    Login
                </Button>
                {hasError && <Alert> Mauvais login</Alert  >}

                <Grid container justifyContent="flex-end">
                    <Grid item></Grid>
                </Grid>
            </form>

        </div>
    );
}

export default Login;
