import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { getAuth } from "firebase/auth";
import {getDatabase} from "firebase/database"

const firebaseConfig={
    apiKey: "AIzaSyC0nFtixs6kPovhfbJ0Hs1uBROL_G2Ii9k",

    authDomain: "timing-28293.firebaseapp.com",

    projectId: "timing-28293",

    storageBucket: "timing-28293.appspot.com",

    messagingSenderId: "445275494451",

    appId: "1:445275494451:web:f177283035d76a7c2b6b46",
    databaseURL: 'https://timing-28293-default-rtdb.europe-west1.firebasedatabase.app',

    measurementId: "G-WMRREND7V7"



}
const firebaseApp=firebase.initializeApp(firebaseConfig);
const authFirebase=firebaseApp.auth();
const database=firebaseApp.database();
const auth=getAuth(firebaseApp);
const db=getDatabase(firebaseApp);


export {authFirebase,database,auth,db};
